import PropTypes from "prop-types"
import React from "react"
import RichText from "../contentful/richtext"
import Img from "gatsby-image"

const Profile = ({home}) => (
<section className="module" id="profile">
	<div className="container">
		<div className="row">
			<div className="col-md-12">
				<div className="m-title c-align">
					<h2>Hervé Cohonner</h2>
					<h6>Photographe professionnel</h6>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-md-4">
				<Img fluid={home.profilePicture.fluid} className="img-responsive"/>
			</div>
			<div className="col-md-4">
				<RichText json={home.profile.json}/>
			</div>			
		</div>
	</div>
</section>
)

Profile.propTypes = {
  home: PropTypes.object,
}

Profile.defaultProps = {
 
}

export default Profile
