import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const PortfolioItem = ({gallery}) => (
    <div className="portfolio-item portfolio-item-on photo">
        <Link to={gallery.topic.url + '/' + gallery.url}>
        <div className="portfolio-wrapper">
            { gallery.imageSquare && <div className="portfolio-img-wrap" style={{'backgroundImage':`url(${gallery.imageSquare.fluid.src})`}}></div> }            
            <div className="portfolio-overlay"></div>
            <div className="portfolio-caption">
                <h5 className="portfolio-title">{gallery.title}</h5>
                <h6 className="portfolio-subtitle">{gallery.topic.title}</h6>
            </div>
        </div>
        </Link>
    </div>
)

PortfolioItem.propTypes = {
    gallery: PropTypes.object
}

PortfolioItem.defaultProps = {
 
}

export default PortfolioItem
