import PropTypes from "prop-types"


import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const options = {
    renderNode: {
        [BLOCKS.HEADING_1]: (node, next) => `
            <h2>${next(node.content)}</h2>
        `,
        [BLOCKS.HEADING_2]: (node, next) => `
            <h3>${next(node.content)}</h3>
        `,
        [BLOCKS.HEADING_3]: (node, next) => `
            <h4>${next(node.content)}</h4>
        `,
        [BLOCKS.HEADING_4]: (node, next) => `
            <h5>${next(node.content)}</h5>
        `,
        [BLOCKS.HEADING_5]: (node, next) => `
            <h6>${next(node.content)}</h6>
        `,
        [BLOCKS.HEADING_6]: (node, next) => `
            <p><strong>${next(node.content)}</strong></p>
        `
    }
}


const RichText = ({json}) => {
    return documentToReactComponents(json, options)   
}


RichText.propTypes = {
  json: PropTypes.object,
}

RichText.defaultProps = {
 
}

export default RichText
