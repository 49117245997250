import React, { Component } from "react"
//import { Link } from "gatsby"
import Layout from "../components/layout/layout"
//import Image from "../components/image"
import SEO from "../components/layout/seo"
import Portfolio from "../components/home/portfolio"
import Profile from "../components/home/profile"
import PropTypes from 'prop-types';


class IndexPage extends Component {

	componentDidMount() {
	}




	render() {

		const content = this.props.data.allContentfulAccueil.edges[0].node;

		return(
		<Layout>
		<SEO title={content.metaTitle || content.title} description={content.metaDescription} keywords={content.metaKeywords} />
	
		<section className="module-header full-height jarallax bg-dark bg-dark-30 text-left" id="home" data-jarallax>
			<img className="jarallax-img" src={content.heroBackground.fluid.src} srcSet={content.heroBackground.fluid.srcSet} sizes={content.heroBackground.fluid.sizes} alt=""/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 className="h1 m-b-15">Hervé <br/>Cohonner</h1>
						<h1 className="h6 m-b-60">Photographe professionel</h1>
						<p><a className="btn btn-brand" href="#contact"><span>Contactez-moi</span></a></p>
					</div>
				</div>
			</div>
		</section>
		<Profile home={content} />
		<Portfolio home={content} />
		
	  </Layout>)
	}
}




IndexPage.propTypes = {
	data: PropTypes.object.isRequired,
	
  }

export default IndexPage

export const staticQuery = graphql`
  query {
    allContentfulAccueil (limit:1) {
      edges {
        node {
          id
		  heroBackground {
			title
			fluid(maxWidth: 1920) {
			  sizes
			  src
			  srcSet
			  aspectRatio
			}
		  }
		  profilePicture {
			title
			fluid(maxWidth: 920, resizingBehavior: FILL) {
				sizes
				src
				srcSet
				aspectRatio
			}
		  }
		  profile {
			json
		  }
		  portfolioTitle
		  portfolioSubTitle
          galeryTopics {
            title
			url
			id
		  }
		  portfolioGalleries {
			  id
			  title
			  url
			  topic {
				  title
				  url
			  }
			  imageSquare {
				fluid(resizingBehavior: FILL, maxWidth: 920, maxHeight: 920) {
				  src
				  srcSet
				  sizes
				}
			  }
		  }
		  contactTitle
		  contactSubTitle
		  metaTitle
		  metaDescription
		  metaKeywords
        }
      }
    }
  }
`