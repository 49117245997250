

import PropTypes from "prop-types"
import React from "react"
import PortfolioItem from "./portfolioItem"

const Portfolio = ({galleries}) => (

<div className="container-fluid">
    <div className="row row-portfolio" data-columns="4">
        {
            galleries.map((gallery) => {
                return <PortfolioItem gallery={gallery} key={gallery.id}/>
            })
        }
    </div>
</div>

)

Portfolio.propTypes = {
    galleries: PropTypes.array
}

Portfolio.defaultProps = {
 
}

export default Portfolio
