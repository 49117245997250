

import PropTypes from "prop-types"
import React from "react"
import Portfolio from "../portfolio"
import { Link } from "gatsby"

const HomePortfolio = ({home}) => (
    <section className="module p-b-0" id="portfolio">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="m-title c-align">
                        <h2>{home.portfolioTitle}</h2>
                        <h6>{home.portfolioSubTitle}</h6>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ul className="filters h6" id="filters">
                        <li><Link to="portfolio">Toutes les galeries</Link></li>

                        {
                            home.galeryTopics.map((topic) => {
                            return (<li key={topic.id}><Link to={topic.url} >{topic.title}</Link></li>)
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
        <Portfolio galleries={home.portfolioGalleries}/>
    </section>
)

HomePortfolio.propTypes = {
    home: PropTypes.object
}

HomePortfolio.defaultProps = {
 
}

export default HomePortfolio
